<template>
  <div v-if="showWidget">
    <div :key="cartRedemptionRef" v-if="giftCardRedemptionActive">
      <fdk-cart class="product__actions" ref="cart">
        <template slot-scope="cart">
          <button style="display: none" @click="handleReload(cart)">
            Reload
          </button>
        </template>
      </fdk-cart>
      <div class="redeem-dialog">
        <div class="vertical-center">
          <div class="card-img">
            <img src="./../../assets/card.svg" alt="close" />
          </div>
          <div class="redeem-content">
            <div class="redeem-heading">
              Have a Gift Card ?
            </div>    
            <div class="redeem-text">
              {{
                "Redeem Points To Save More"
              }}
            </div>
          </div>
        </div>
        <div class='apply-remove-btns'>
          <div>
            <button class="click-remove-button" @click="removeGift" :disabled="totalBalance > 0 ? false : true"  >
              X
            </button>
          </div>
          <div>
            <button :disabled="cartValueZero" @click="openDialog" class="click-apply-button">
              APPLY GIFTCARD
            </button>
          </div>
        </div>
      </div>

      <Modal
        title="Redeem Card Points"
        @close="closeDialog"
        v-if="show"
        :show="show"
      >
        <template slot="body">
          <div v-if='!isRemove'>
            <CheckBalance
              :errorMessage="errorMessage"
              @handleCheck="handleCheck"
              v-show="!checked"
              :apiCall="apiCall"
            />
            <ApplyBalance
              :errorMessage="errorMessage"
              :cardNumber="cardNumber"
              :balance="fetchedBalance"
              @handleApply="handleApply"
              v-show="checked"
              :buttonType="buttonText"
              :apiCall="apiCall"
            />
          </div>
          <div v-if='isRemove'>
              <ApplyBalance
              :balance="totalBalance"
              @handleApply="handleApply"
              :buttonType="buttonText"
              :apiCall="apiCall"
              :multiCards="cardList"
              :isCancel="isRemove"
              :totalRedeemBalance="totalBalance"
            />
          </div>
        </template>
      </Modal>
      <div :v-if="applied && totalBalance" class="success-popup">
        <SuccessModal
          :show="applied"
          :balanceValue="redeemAmount"
          @closeSucessModal="handleCloseSuccess"
        />
      </div>
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import CheckBalance from "./checkBalance.vue";
import ApplyBalance from "./applyBalance.vue";
import SuccessModal from "./successModal.vue";
import Modal from "./../modal/Model.vue";

import {
  balanceEnquiry,
  checkRedeem,
  applyGiftcard,
  removeGiftcard
} from "../../services/qwikcilver-core.service";
import {
  getCartInfo,
} from "../../services/cart.service";
import {
  getCartId,
  isThisBuyNow,
  setZeroValueCheckout,
} from "./../../utils";

import { getRedeemSettings } from "../../services/settings.service";

import { getEnvs } from "./../../services/env.service";
export default {
  name: "balance-redeemption",
  components: {
    CheckBalance,
    ApplyBalance,
    SuccessModal,
    Modal,
  },
  data() {
    return {
      cardNumber: "",
      cardPin: "",
      checked: false,
      applied: false,
      show: false,
      buttonText: "APPLY",
      totalBalance: 0,
      articleList: [],
      userData: null,
      cartData: null,
      existingRedeemData: null,
      ifExistingRedeemExist: false,
      fetchedBalance: null,
      cartRedemptionRef: 0,
      redeemAmount: 0,
      giftCardRedemptionActive: false,
      rowID: "",
      disable: false,
      toastMessage: "",
      errorMessage: false,
      toastType: "",
      showMsg: false,
      apiCall: false,
      uid: false,
      user: null,
      cartValue: 0,
      isMultiCard : false,
      cartList : [],
      isRemove : false,
      cartValueZero:false
    };
  },

  async mounted() {
    window.FPI.state.user.subscribe((data) => {
      if (data && data.user) {
        this.user = data.user;
      }
    });
    let cart_id = getCartId();
    await this.isGiftCardRedemptionActive();
    await this.getCartDetails(cart_id);
    await this.checkExistingRedeem(cart_id);
    await this.isZeroCartActive();
  },

  watch: {
    show() {
      if (this.show) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
    },
  },
  methods: {
    /**
     * cart event
     */

    async handleUpdateCartEvent() {
      this.handleApply(true);
    },

    /**
     * Method to trigger show dialog
     */
    openDialog() {
      this.isRemove = false;
      if (!this.disable) {
        this.show = true;
      }
    },
    removeGift(){
      this.show = true;
      this.buttonText = "REMOVE";
      this.isRemove = true;
      this.cardNumber = "";
      this.cardPin = "";
      this.fetchedBalance = 0;
      this.isMultiCard = false;
    },
    /**
     * Method to trigger close dialog
     */
    closeDialog() {
      if (this.apiCall) {
        return;
      }
      this.cardNumber = "";
      this.cardPin = "";
      this.show = false;
      this.checked = false;
      this.buttonText = "APPLY"
    },

    forceRerender() {
      this.cartRedemptionRef += 1;
    },

    /**
     * Method to trigger balance enquiry
     * @param {*} value : Values received from checkBalance.vue on CardNumber and CardPin applied.
     */
    async handleCheck(value) {
      try {
        this.cardNumber = value.cardNumber;
        this.cardPin = value.cardPin;
        if (value.cardNumber && value.cardPin) {
          this.balanceCheck();
        } else {
          let msg = !value.cardNumber ? "Card Number" : "Card Pin";
          this.errorMessage = `Please provide ${msg} `;
        }
      } catch (error) {
        console.error(error?.message);
      }
    },

    /**
     *
     * @param {*}
     */
    async handleApply(isRemove) {
      try {
        if (!isRemove) {
          await this.applyGiftCard();
        } else {
          await this.cancelRedeem();
          this.closeDialog();
          this.forceRerender();
          this.$forceUpdate();
          this.totalBalance = 0;   
          this.cartValueZero = false;       
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    /**
     * Method to handle close and success flags
     */
    async handleCloseSuccess() {
      this.applied = false;
      this.show = false;
      this.closeDialog();
      await this.handleReload();
    },

    /**
     * Applying the qwikcilver giftcard with redeem
     */
    async applyGiftCard() {
      try {
        let cart_id = getCartId();
        let redeemableValue = this.cartValue ? ( this.cartValue < this.fetchedBalance ? this.cartValue - 1 : this.fetchedBalance ) : this.fetchedBalance;
        let body = {
            card_number: this.cardNumber,
            card_pin: this.cardPin,
            amount: redeemableValue
        };
        let res = await applyGiftcard(cart_id,body);
        if(res.data && res.data.status && res.data.status == 'success'){
          this.applied = true;
          this.redeemAmount = res.data.data.redeemed_amount;
          this.totalBalance += res.data.data.redeemed_amount;
          this.rowID = res.data.data.row_id;
          this.uid = res.data.data.uid;
          this.cardList = [...(this.cardList?.length ? this.cardList : []), this.cardNumber]
        }else{
          this.errorMessage = res?.data?.message;
          this.apiCall = false;
        }
      } catch (error) {
        this.apiCall = false;
        this.errorMessage = error?.response?.data?.message;
      }
    },

    async balanceCheck() {
      try {
        this.errorMessage = false;
        this.apiCall = true;
        let reqBody = {
          card_number: this.cardNumber,
          card_pin: this.cardPin,
        };
        let cart_id = getCartId();
        const response = await balanceEnquiry(
          reqBody,
          cart_id
        );
        this.fetchedBalance = response?.data?.data?.balance;
        this.apiCall = false;
        if (!response?.data?.data?.balance) {
          this.errorMessage = `Card Balance is ${response?.data?.data?.balance}`;
        } else {
          if (this.fetchedBalance > 0) {
            this.checked = true;
          }
          this.toastMessage = "Successfully fetched the balance";
          this.toastType = "success";
          this.showMsg = true;
          this.errorMessage = false;
        }
      } catch (error) {
        this.apiCall = false;
        this.errorMessage =
          error?.response?.data?.data?.message == "Validation Failed."
            ? "Validation Failed !"
            : error?.response?.data?.data?.message;
      }
    },
    async encryptPayload(payload, publicKey) {
      try {
        // Convert the payload to ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(payload);

        // Import the public key
        const publicKeyObject = await crypto.subtle.importKey(
          "spki",
          publicKey,
          {
            name: "RSA-OAEP",
            hash: "SHA-256",
          },
          false,
          ["encrypt"]
        );

        // Encrypt the data
        const encryptedData = await crypto.subtle.encrypt(
          {
            name: "RSA-OAEP",
          },
          publicKeyObject,
          data
        );

        // Convert encrypted data to base64
        const encryptedDataBase64 = btoa(
          String.fromCharCode(...new Uint8Array(encryptedData))
        );
        return encryptedDataBase64;
      } catch (error) {
        console.error("Encryption error:", error);
        throw error;
      }
    },
    async handleReload() {
      if (this.$refs["cart"]) {
        await this.$refs["cart"].getCart();
      } else {
        window.location.reload();
      }
    },

    async cancelRedeem() {
      try {
        this.apiCall = true;
        let params = {
          uid: this.uid,
          id: this.rowID,
        }
        await removeGiftcard(params);
        this.totalBalance = 0;
        this.checked = false;
        this.show = false;
        this.applied = false;
        this.buttonText = "APPLY";
        this.redeemAmount = 0;
        this.rowID = false;
        this.apiCall = false;
        this.uid = false;
        this.cardList = [];
        this.isMultiCard = false;
        this.fetchedBalance = 0;
        this.handleReload();
      } catch (error) {
        this.apiCall = false;
        console.error(error?.message);
        this.errorMessage = error.message;
      }
    },
    async isGiftCardRedemptionActive() {
      try {
        const response = await getRedeemSettings();
        if (response?.data?.data?.active) {
          this.giftCardRedemptionActive = true;
        } else {
          this.giftCardRedemptionActive = false;
        }
      } catch (error) {
        console.error("ERROR_ISGIFTCARDREDEMPTIONACTIVE", error?.message);
      }
    },
    async getCartDetails(cart_id){
      try {
        let cartInfo = await getCartInfo(cart_id, isThisBuyNow());
        let cartData = cartInfo?.data?.data;
        if (cartInfo?.data?.status == "success" && cartInfo?.data?.data) {
          this.cartValue = cartData?.breakup_values?.raw?.total || 0;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async checkExistingRedeem(cart_id){
      try {
        this.existingRedeemData = await checkRedeem(cart_id);
        if (
          this.existingRedeemData?.data?.status == "success" &&
          this.existingRedeemData?.data?.data
        ) {
          this.ifExistingRedeemExist = true;
          this.totalBalance = this.existingRedeemData?.data?.data?.amount;
          this.cardNumber = this.existingRedeemData?.data?.data?.card_number;
          this.cardList = [...this.existingRedeemData?.data?.data?.card_number];
          this.rowID = this.existingRedeemData?.data?.data?.row_id;
          this.uid = this.existingRedeemData?.data?.data?.uid;
          this.isMultiCard = true;
        }
      } catch (error) {
        console.log('error:', error)
      }
    },
    async isZeroCartActive(){
      try {
        let response = await getEnvs();
        setZeroValueCheckout(response?.data?.ZERO_VALUE_CHECKOUT);
      } catch (error) {
        console.log('error:', error)
      }
    }
  },
  computed: {
    showWidget: function () {
      const buynow = isThisBuyNow();
      return !!this.user && !buynow;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

.success-popup .modal-header {
  display: none;
}

.success-popup .modal-mask {
  justify-content: center;
  align-items: center;
}

.success-popup .modal-container {
  width: 312px;
  min-height: max-content;
  /* padding: 24px; */
}

.redeem-dialog {
  position:relative;
  background: #fff;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.redeem-content .redeem-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
}
.card-img {
  margin-right: 10px;
}
.redeem-content .redeem-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
  color: #4d4d4e;
}
.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prevent-scroll {
  overflow: hidden !important;
}
.click-apply-button {
  color: var(--linkColor, #48e868);
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background: transparent;
}
.click-remove-button{
  background: var(--linkColor, #de825a);
  position: absolute;
  color: #fff;
  width: 23px;
  margin-left: -36px;
  margin-top: -1px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}
.click-remove-button:hover{
  opacity: 0.9;
}
.disable-giftcard {
  opacity: 0.5;
  pointer-events: none;
}
.click-remove-button:disabled{
  background: var(--linkColor, #de825a);
  cursor: not-allowed;
  display: none;
}
.click-apply-button:disabled{
  background: transparent;
}
</style>
